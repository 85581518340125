<template>
    <st-page
        :title="$t('CONTROL_TYPE.LIST.TITLE')"
    >
        <loader v-if="isLoading"/>
        <template #toolbar>
            <control-type-list-toolbar ref="control-type-list-toolbar"/>
        </template>
        <control-type-list-filter>
            <control-type-list-table @editControl="editControl"/>
        </control-type-list-filter>
    </st-page>
</template>

<script>
    import ControlTypeListFilter from '../components/ControlTypeListFilter';
    import ControlTypeListTable from '../components/ControlTypeListTable';
    import ControlTypeListToolbar from '../components/ControlTypeListToolbar';
    import { mapGetters } from "vuex";

    export default {
        name: 'ControlTypeList',
        components: {
            ControlTypeListFilter,
            ControlTypeListTable,
            ControlTypeListToolbar,
        },
        computed: {
            ...mapGetters({
                loading: 'shared/loading',
            }),
            loadingFetch() {
                return this.loading['controlType/get'];
            },
            isLoading() {
                return this.loadingFetch;
            },
        },
        methods: {
            editControl() {
                this.$refs['control-type-list-toolbar'].editControlType();
            }
        }
    }
</script>
