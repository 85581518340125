<template>
    <div>
        <Loader v-if="optionsLoading" />
        <form
            class="form"
            novalidate="novalidate"
            id="st_modal_control_type_form"
        >
            <div class="row pb-6">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>
                            {{ fields.name.label }} *
                        </label>
                        <st-input-text
                            :ref="fields.name.name"
                            :name="fields.name.name"
                            v-model="model[fields.name.name]"
                        />
                    </div>
                    <div class="form-group">
                        <label>
                            {{ fields.description.label }}
                        </label>
                        <b-form-textarea
                            :ref="fields.description.name"
                            :name="fields.description.name"
                            v-model="model[fields.description.name]"
                        ></b-form-textarea>
                    </div>
                    <div class="form-group">
                        <label>
                            {{ fields.status.label }} *
                        </label>
                        <b-form-select
                            v-model="model[fields.status.name]"
                            :options="fields.status.options"
                            :ref="fields.status.name"
                            :name="fields.status.name">
                            <template #first>
                                <b-form-select-option
                                    :value="null"
                                    disabled
                                >
                                    {{ $t("GENERAL.SELECT_PLACEHOLDER")}}
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                    </div>
                   
                    <div class="form-group">
                        <label>
                            {{ fields.tenant_id.label }}
                        </label>
                        <b-form-select
                            v-model="model[fields.tenant_id.name]"
                            :options="fields.tenant_id.options"
                            :ref="fields.tenant_id.name"
                            :name="fields.tenant_id.name"
                        >
                            <template #first>
                                <b-form-select-option
                                    :value="null"
                                    disabled
                                >
                                    {{ $t("GENERAL.SELECT_PLACEHOLDER") }}
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>
                            {{ fields.form_id.label }} *
                        </label>
                        <b-form-select
                            v-model="model[fields.form_id.name]"
                            :options="fields.form_id.options"
                            :ref="fields.form_id.name"
                            :name="fields.form_id.name"
                        >
                            <template #first>
                                <b-form-select-option
                                    :value="null"
                                    disabled
                                >
                                    {{ $t("GENERAL.SELECT_PLACEHOLDER") }}
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                    </div>
                    <div class="form-group">
                        <label>
                            {{ fields.document_series_type_id.label }} *
                        </label>
                        <b-form-select
                            v-model="model[fields.document_series_type_id.name]"
                            :options="fields.document_series_type_id.options"
                            :ref="fields.document_series_type_id.name"
                            :name="fields.document_series_type_id.name"

                        >
                            <template #first>
                                <b-form-select-option
                                    :value="null"
                                    disabled
                                >
                                    {{ $t("GENERAL.SELECT_PLACEHOLDER") }}
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                    </div>

                    <div class="form-group">
                        <label>
                            {{ fields.output_document_series_type_id.label }} *
                        </label>
                        <b-form-select
                            v-model="model[fields.output_document_series_type_id.name]"
                            :options="fields.output_document_series_type_id.options"
                            :ref="fields.output_document_series_type_id.name"
                            :name="fields.output_document_series_type_id.name"
                        >
                            <template #first>
                                <b-form-select-option
                                    :value="null"
                                    disabled
                                >
                                    {{ $t("GENERAL.SELECT_PLACEHOLDER") }}
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                    </div>

                    <div class="form-group">
                        <label>
                            {{ fields.document_template_id.label }}
                        </label>
                        <b-form-select
                            v-model="model[fields.document_template_id.name]"
                            :options="fields.document_template_id.options"
                            :ref="fields.document_template_id.name"
                            :name="fields.document_template_id.name"

                        >
                            <template #first>
                                <b-form-select-option
                                    :value="null"
                                    disabled
                                >
                                    {{ $t("GENERAL.SELECT_PLACEHOLDER") }}
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                    </div>

                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { FormSchema } from "@/shared/form/form-schema";
import { createFormValidation } from "@/shared/utils/create-form-validation";
import { ControlTypeModel } from "@/modules/control-type/control-type-model";

const { fields } = ControlTypeModel;

const formSchema = new FormSchema([
    fields.id,
    fields.name,
    fields.description,
    fields.status,
    fields.form_id,
    fields.document_template_id,
    fields.document_series_type_id,
    fields.output_document_series_type_id,
    fields.tenant_id,
]);

import { mapActions, mapGetters } from "vuex";

export default {
    name: "ControlTypeForm",
    props: {
        editMode: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            rules: formSchema.rules(),
            model: null,
            optionsLoading: false,
        };
    },
    computed: {
        ...mapGetters({
            record: "controlType/form/record",
            filters: "controlType/list/filters",
        }),
        headerText() {
            return this.editMode
                ? this.$t("CONTROL_TYPE.FORM.EDIT_TITLE")
                : this.$t("CONTROL_TYPE.FORM.ADD_TITLE");
        },
        fields() {
            return fields;
        },
    },
    methods: {
        ...mapActions({
            doCreate: "controlType/form/create",
            doUpdate: "controlType/form/update",
            doFetch: "controlType/list/doFetch",
        }),
        hide() {
            this.$emit('cancelForm');
        },
        doReset() {
            this.model = formSchema.initialValues(this.record);
        },
        show() {
            this.$refs['modal'].show();
        },
        async doSubmit() {
            const validate = await this.fv.validate();
            if (validate === "Valid") {
                const { id, ...values } = formSchema.cast(this.model);

                if (this.model.document_template_id) {
                    const selectedDocumentTemplate = this.fields.document_template_id.options
                        .find(documentTemplate => documentTemplate.value === this.model.document_template_id);
                    values.document_template_name = selectedDocumentTemplate.text;
                }

                if (this.model.tenant_id) {
                    const selectedTenant = this.fields.tenant_id.options
                        .find(option => option.value === this.model.tenant_id);
                    values.tenant_name = selectedTenant.text;
                }

                if (this.editMode) {
                    this.doUpdate({ id, values }).then(() => this.hide());
                } else {
                    this.doCreate(values).then(() => {
                        this.hide();
                        this.doFetch(this.filters);
                    });
                }
            }
        },
    },
    created() {
        this.model = formSchema.initialValues(this.record);
    },
    async mounted() {
        this.fv = createFormValidation("st_modal_control_type_form", this.rules);

        this.optionsLoading = true;

        for (let key in fields) {
            if (fields[key]?.fetchFn ) {
                await fields[key].updateOptions();
            }
        }

        this.optionsLoading = false;
    },
};
</script>
