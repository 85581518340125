
import RelationToOneField from '@/shared/fields/relation-to-one-field';
import { FormsService } from '@/modules/forms/forms-service';

export class FormsField {
    static relationToOne(name, label, options) {
        return new RelationToOneField(
            name,
            label,
            FormsService.listAutocomplete,
            (record) => {
                if (!record) {
                    return '';
                }

                return {
                    value: record.id,
                    text: record.name,
                };
            },
            options,
        );
    }
}
