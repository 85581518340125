
import RelationToOneField from '@/shared/fields/relation-to-one-field';
import { DocumentTemplateService } from '@/modules/document-template/document-template-service';

export class DocumentTemplateField {
    static relationToOne(name, label, options) {
        return new RelationToOneField(
            name,
            label,
            DocumentTemplateService.listAutocomplete,
            (record) => {
                if (!record) {
                    return '';
                }

                return {
                    value: record.id,
                    text: record.name,
                };
            },
            options,
        );
    }
}
