<template>
    <div>
        <st-button
            variant="secondary"
            :callback="addControlType"
        >
            <i class="fa fa-plus"></i>
            {{ $t('CONTROL_TYPE.ADD_BUTTON') }}
        </st-button>
        <control-type-form-modal
            ref="controlTypeFormModal"
            :edit-mode="editMode"
        ></control-type-form-modal>
    </div>
</template>

<script>
    import ControlTypeFormModal from '@/modules/control-type/components/ControlTypeFormModal.vue';

    export default {
        name: 'ControlTypeListToolbar',
        components: { ControlTypeFormModal },
        data() {
            return {
                editMode: false,
            };
        },
        methods: {
            addControlType() {
                this.editMode = false;
                this.$refs.controlTypeFormModal.show();
            },
            editControlType() {
                this.editMode = true;
                this.$refs.controlTypeFormModal.show();
            }
        }
    };
</script>

